<template>
  <div>
    <ErrorPage v-if="showError" />
    <div class="flex flex-col gap-4 w-full" v-else>
      <c-card class="w-full flex flex-col p-5">
        <div class="w-full gap-5 mb-6 flex" v-if="mode === 'admin'">
          <div
            class="w-3/7 flex justify-between gap-6 p-5 bg-ceruleanBlue shadow rounded-md"
          >
            <c-icon
              icon-name="icon-expense-2"
              class-name="text-white"
              size="s"
            />
            <div class="flex justify-start items-center gap-4 divide-x">
              <div class="flex flex-col justify-center items-start">
                <span class="font-semibold text-xs uppercase text-white"
                  >Total allowance requested (YTD)</span
                >
                <span class="font-bold text-lg text-white">{{
                  convertToCurrency(leaveStatistics.totalAllowance)
                }}</span>
              </div>
              <div class="flex flex-col justify-center items-start pl-4">
                <span class="font-semibold text-xs uppercase text-white"
                  >total paid (YTD)</span
                >
                <span class="font-bold text-lg text-white">{{
                  convertToCurrency(leaveStatistics.totalPaid)
                }}</span>
              </div>
            </div>
          </div>
          <c-card class="w-4/7 flex justify-between gap-4 py-6 px-4">
            <c-card class="flex justify-center items-center p-2">
              <c-icon class-name="text-jet" icon-name="icon-union" size="s" />
            </c-card>
            <div class="flex items-center gap-4 divide-x divide-romanSilver">
              <div class="flex flex-col">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Pending Requests</span
                >
                <span class="text-lg font-extrabold text-carrotOrange">{{
                  leaveStatistics.pendingRequests
                }}</span>
              </div>
              <div class="flex flex-col pl-4">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Approved Requests</span
                >
                <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                  leaveStatistics.approvedRequests
                }}</span>
              </div>
              <div class="flex flex-col pl-4">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Disapproved Requests</span
                >
                <span class="text-lg font-extrabold text-desire">{{
                  leaveStatistics.disapprovedRequests
                }}</span>
              </div>
              <div class="flex flex-col pl-4">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Cancelled Requests</span
                >
                <span class="text-lg font-extrabold text-desire">{{
                  leaveStatistics.cancelledRequests || 0
                }}</span>
              </div>
            </div>
          </c-card>
        </div>

        <div class="w-full gap-5 mb-6 flex" v-else>
          <c-card class="w-full flex gap-6 py-6 px-4">
            <c-card class="flex justify-center items-center p-2">
              <c-icon class-name="text-jet" icon-name="icon-union" size="s" />
            </c-card>
            <div class="flex items-center gap-8">
              <div class="flex flex-col gap-1">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Annual Leave Requests</span
                >
                <div class="flex gap-4">
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{
                      leaveStatistics.annualLeaveRequests
                        .totalAnnualLeaveDaysApproved
                    }}
                    days <span class="text-mediumSeaGreen">Approved</span>
                  </span>
                  <div class="h-5 border" style="width: 0.5px" />
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{
                      leaveStatistics.annualLeaveRequests
                        .totalAnnualLeaveDaysTaken
                    }}
                    days <span class="text-mediumSeaGreen">Taken</span>
                  </span>
                </div>
              </div>

              <div class="h-full border" style="width: 0.5px" />

              <div class="flex flex-col gap-1">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Others</span
                >
                <div class="flex gap-4">
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{
                      leaveStatistics.otherLeaveRequests
                        .totalOtherLeaveDaysApproved
                    }}
                    days <span class="text-carrotOrange">Booked</span>
                  </span>
                  <div class="h-5 border" style="width: 0.5px" />
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{
                      leaveStatistics.otherLeaveRequests
                        .totalOtherLeaveDaysTaken
                    }}
                    days <span class="text-carrotOrange">Taken</span>
                  </span>
                </div>
              </div>

              <div class="h-full border" style="width: 0.5px" />

              <div class="flex flex-col gap-1">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Sickness (Days)</span
                >
                <div class="flex gap-4">
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{
                      leaveStatistics.sickLeaveRequests
                        .totalSickLeaveDaysApproved
                    }}
                    <span class="text-blueCrayola">sick</span> days
                  </span>
                  <div class="h-5 border" style="width: 0.5px" />
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{
                      leaveStatistics.sickLeaveRequests.totalSickLeaveDaysTaken
                    }}
                    <span class="text-blueCrayola">working</span> days
                  </span>
                </div>
              </div>

              <div class="h-full border" style="width: 0.5px" />

              <div class="flex flex-col gap-1">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Pending Requests</span
                >
                <div class="flex gap-4">
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{ leaveStatistics.pending }} days
                    <span class="text-desire">Planned</span>
                  </span>
                  <div class="h-5 border" style="width: 0.5px" />
                  <span class="text-sm font-semibold text-romanSilver"
                    >{{ "---" }} days <span class="text-desire">Taken</span>
                  </span>
                </div>
              </div>
            </div>
          </c-card>
        </div>

        <template v-slot:footer>
          <CardFooter
            printcard
            reloadcard
            search-placeholder="Search by leave type or details"
            @reload="getAdminLeave"
            @actionModal="isOpenFilter = true"
            @sortType="
              $queryBuilder(
                { sort: $event },
                mode === 'admin' ? getAdminLeave : getEmployeeLeaveRequest
              )
            "
            @searchResult="
              $queryBuilder(
                { search: $event },
                mode === 'admin' ? getAdminLeave : getEmployeeLeaveRequest
              )
            "
          />
        </template>
      </c-card>

      <c-table
        :headers="headers"
        :items="leaveRequests"
        aria-label="leave requests table"
        :loading="isFetching"
        :has-number="false"
        :page-sync="true"
        :pagination-list="metaData"
        @page="
          $queryBuilder(
            { page: $event },
            mode === 'admin' ? getAdminLeave : getEmployeeLeaveRequest
          )
        "
        @itemsPerPage="
          $queryBuilder(
            { perPage: $event },
            mode === 'admin' ? getAdminLeave : getEmployeeLeaveRequest
          )
        "
        v-if="leaveRequests.length || isFetching"
      >
        <template v-slot:item="{ item }">
          <div
            v-if="item.employee"
            class="flex justify-start items-start gap-2"
          >
            <div>
              <span
                v-if="item.data.employee.photo"
                class="flex justify-center items-center w-9 h-9"
              >
                <img
                  :src="item.data.employee.photo"
                  class="w-9 h-9 rounded"
                  alt="profile photo"
                />
              </span>
              <div
                v-else
                class="flex justify-center items-center w-9 h-9 rounded border"
              >
                <span
                  class="text-blueCrayola text-center font-semibold text-lg p-2"
                >
                  {{
                    $getInitials(
                      `${item.data.employee.fname} ${item.data.employee.lname}`
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="flex flex-col justify-start gap-1">
              <span class="font-semibold text-darkPurple text-base">
                {{ item.data.employee.fname }} {{ item.data.employee.lname }}
              </span>
              <span class="font-normal text-romanSilver text-xs uppercase">
                {{
                  item.data.employee.userDesignation &&
                  item.data.employee.userDesignation.name
                }}
              </span>
            </div>
          </div>
          <span
            v-if="item.leaveType"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.leaveType }}
          </span>
          <span v-if="item.details" class="text-darkPurple text-sm font-normal">
            {{ item.data.details }}
          </span>
          <div v-if="item.from" class="flex flex-col justify-start gap-1">
            <div
              class="text-darkPurple text-sm font-semibold whitespace-nowrap"
            >
              {{ $DATEFORMAT(new Date(item.data.from), "MMMM dd, yyyy") }}
              to<br />
              {{ $DATEFORMAT(new Date(item.data.to), "MMMM dd, yyyy") }}
            </div>
            <span class="text-carrotOrange text-sm">
              {{ item.data.daysRequested }} Day(s)
            </span>
          </div>
          <span
            v-if="item.allowanceIsRequested"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.allowanceIsRequested }}
          </span>
          <div
            v-if="item.delegatee"
            class="flex justify-start items-start gap-2"
          >
            <template v-if="Object.keys(item.data.delegatee).length">
              <div>
                <span
                  v-if="item.data.delegatee.photo"
                  class="flex justify-center items-center w-9 h-9"
                >
                  <img
                    :src="item.data.delegatee.photo"
                    class="w-9 h-9 rounded"
                    alt="profile photo"
                  />
                </span>
                <div
                  v-else
                  class="flex justify-center items-center w-9 h-9 rounded border"
                >
                  <span
                    class="text-blueCrayola text-center font-semibold text-lg p-2"
                  >
                    {{
                      $getInitials(
                        `${item.data.delegatee.fname} ${item.data.delegatee.lname}`
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-1">
                <span class="font-semibold text-darkPurple text-base">
                  {{ item.data.delegatee.fname }}
                  {{ item.data.delegatee.lname }}
                </span>
                <span class="font-normal text-romanSilver text-xs uppercase">
                  {{ item.data.delegatee.designation }}
                </span>
              </div>
            </template>
            <span v-else>Nil</span>
          </div>
          <div
            v-if="item.status"
            class="rounded-md p-2 text-sm w-28 text-center capitalize"
            :class="{
              'bg-green-100 text-mediumSeaGreen':
                item.data.status === 'approved',
              'bg-yellow-100 text-carrotOrange': item.data.status === 'pending',
              'bg-red-100 text-flame': item.data.status === 'disapproved',
            }"
          >
            {{ item.data.status }}
          </div>

          <div v-if="item.requestId">
            <c-menu left @click.stop="">
              <template v-slot:title>
                <c-icon icon-name="more_icon" size="xs" />
              </template>
              <div
                class="flex flex-col w-48 h-18 p-2 justify-start items-start"
              >
                <div
                  class="w-full"
                  @click="checkPrivileges('view details', item.data)"
                >
                  <div class="hover:bg-ghostWhite w-full rounded-md">
                    <div
                      class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                    >
                      <c-icon
                        icon-name="icon-eye"
                        class-name="text-darkPurple"
                        size="xs"
                      />
                      <span class="text-darkPurple text-sm">View Details</span>
                    </div>
                  </div>
                </div>
                <div
                  class="w-full"
                  @click="checkPrivileges('view approval', item.data.requestId)"
                >
                  <div class="hover:bg-ghostWhite w-full rounded-md">
                    <div
                      class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                    >
                      <c-icon
                        icon-name="icon-upgrade"
                        class-name="text-blueCrayola"
                        size="xs"
                      />
                      <span class="text-darkPurple text-sm"
                        >Approval Status</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </c-menu>
          </div>
        </template>
      </c-table>

      <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
        <Icon
          icon-name="paygrade_Illustration"
          size=""
          class-name="w-56 h-56"
        />
        <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
          Leave requests can be found here. This is empty now, but you can start
          adding leave requests to populate this area.
        </p>
      </div>

      <FilterTable
        v-if="isOpenFilter"
        :filter-data="filterOptions"
        :show-search="false"
        @close="isOpenFilter = false"
        @applyFilter="onSubmitFilter($event)"
      />
      <ViewLeaveRequest ref="viewRequestRef" />
      <ApprovalStatus ref="approvalStatusRef" :mode="mode" />
    </div>
  </div>
</template>

<script>
import { differenceInDays } from "date-fns";
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
import CIcon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import ApprovalStatus from "@/components/ApprovalStatus";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    CCard,
    CTable,
    ErrorPage,
    CIcon,
    CMenu,
    CardFooter,
    FilterTable,
    ApprovalStatus,
    ViewLeaveRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/ViewLeaveRequest"
      ),
  },
  data() {
    return {
      differenceInDays,
      showError: false,
      isOpenFilter: false,
      isOpenAddLeave: true,
      isOpenLeave: false,
      isFetching: true,
      isOpenSelectEmployee: true,
      setQueryTimeout: null,
      queryParams: {},
      employees: [],
      selectedDelegatee: {},
      leaveRequests: [],
      metaData: {},
      leaveStatistics: {},
      headers: [
        { title: "Employee Name", value: "employee" },
        { title: "Leave Type", value: "leaveType" },
        { title: "Description", value: "details" },
        { title: "Duration", value: "from" },
        { title: "Allowance Requested", value: "allowanceIsRequested" },
        { title: "Delegatee", value: "delegatee" },
        { title: "status", value: "status" },
        { title: "", value: "requestId", image: true },
      ],
      filterOptions: [
        {
          header: "Status",
          value: "status",
          optionsBox: [
            { name: "Pending", id: "pending" },
            { name: "Approved", id: "approved" },
            { name: "Disapproved", id: "disapproved" },
            { name: "Cancelled", id: "cancelled" },
          ],
        },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  methods: {
    async checkPrivileges(slug, data) {
      switch (slug) {
        case "view details":
            if (this.mode === 'admin'){
              try {
                await this.$handlePrivilege("leaveRequest", "viewDetails");
                this.$refs.viewRequestRef.onOpen(data);
              } catch (error) {
                this.$toasted.error(
                  "You do not have permission to perform this task",
                  {
                    duration: 5000,
                  }
                );
              }
            }
            else{
                this.$refs.viewRequestRef.onOpen(data);
            }
          break;

        case "view approval":
            if (this.mode === 'admin'){
              try {
                await this.$handlePrivilege("leaveRequest", "viewApprovalStatus");
                this.$refs.approvalStatusRef.toggle(data);
              } catch (error) {
                this.$toasted.error(
                  "You do not have permission to perform this task",
                  {
                    duration: 5000,
                  }
                );
              }
            }
            else{
                this.$refs.approvalStatusRef.toggle(data);
            }
          break;
        default:
          break;
      }
    },
    onSubmitFilter(options) {
      this.$queryBuilder(
        options[0],
        this.mode === "admin"
          ? this.getAdminLeave
          : this.getEmployeeLeaveRequest
      );
      this.isOpenFilter = false;
    },
    async getAdminLeave(params) {
      this.isFetching = true;
      await this.$_getAdminLeave(params)
        .then(({ data }) => {
          if (data.employees) this.leaveRequests = data.employees;
          if (data.meta) this.metaData = data.meta;
          this.isFetching = false;
        })
        .catch(() => {
          this.$toasted.error(
            "Error fetching data, please check your network.",
            { duration: 3000 }
          );
          this.isFetching = false;
        });
    },
    async getLeaveStatistics() {
      await this.$_getAdminLeaveStats().then((response) => {
        this.leaveStatistics = response.data;
      });
    },
    async getEmployeeLeaveRequest(params) {
      this.isFetching = true;
      await this.$_getManagersEmployeeLeaveRequest(params)
        .then(({ data }) => {
          if (data.employees) this.leaveRequests = data.employees;
          if (data.meta) this.metaData = data.meta;
          this.isFetching = false;
        })
        .catch((error) => {
          const message = "Something went wrong! Try again";
          this.$toasted.error(error || message, { duration: 3000 });
          this.isFetching = false;
        });
    },
    async getManagerLeaveStatistics() {
      this.isFetching = true
      try {
        await this.$_getManagersLeaveAnalytics().then((response) => {
          this.leaveStatistics = response.data;
          this.isFetching = false
        });
      }
      catch (err) {
        this.$toasted.error('Error fetching stats', {duration: 5000})
        this.isFetching =  false
      }
    },
    async bootstrapModule() {
      if (this.mode === "admin") {
      this.isFetching = true;
      try {
        await this.$handlePrivilege("leaveRequest", "viewRegister");
        await this.getAdminLeave();
        await this.getLeaveStatistics();
        this.showError = false;
      this.isFetching = false;
      } catch (error) {
        this.showError = true;
      this.isFetching = false;
      }
      } else {
        await this.getEmployeeLeaveRequest("");
        await this.getManagerLeaveStatistics();
      }
    },
  },
  async created() {
      await this.bootstrapModule();
  },
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

input[type="radio"]:checked {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
